.container {width: 288px; display: block; margin: 0 auto 60px;}
.useMarginTop { margin-top: 80px;}
.useMarginTop:before { content: ""; clear: both; display: table;}
.img{ width: 100%; height:200px; background: no-repeat center top; background-size: 328px 200px;position:relative; }
.text { white-space: pre-line; color: #222; letter-spacing: var(--letter-spacing); font-size: 14px; font-weight: 400; line-height:1.6; width: 100%; text-align: center; }

/* Character @1X */
.default        { background-image: url(./images/1x/gogo.png);}
.no_result_01   { background-image: url(./images/1x/no-result-01.png); margin-top: -20px;}
.no_result_02   { background-image: url(./images/1x/no-result-02.jpg); margin-top: -20px;}
.no_result_03   { background-image: url(./images/1x/no-result-03.png); margin-top: -15px;}
.no_result_04   { background-image: url(./images/1x/no-result-04.png); margin-top:-30px}
.no_result_05   { background-image: url(./images/1x/no-result-05.png); margin-top:-19px;}
.no_result_06   { background-image: url(./images/1x/no-result-06.png); margin-top: -15px;}
.no_result_07   { background-image: url(./images/1x/no-result-07.png); margin-top: -32px;}
.no_result_08   { background-image: url(./images/1x/no-result-08.jpg);}
.no_menu        { background-image: url(./images/1x/no-menu.png);}
.welcome        { background-image: url(./images/1x/welcome.png); margin-top:-22px;}
.like           { background-image: url(./images/1x/like.png); margin-top:-29px}
.gogo           { background-image: url(./images/1x/gogo.png); margin-top: -22px;}
.teacher        { background-image: url(./images/1x/teacher.png);}
.congratulation { background-image: url(./images/1x/congratulation.png);}
	
/* Character @2X */
@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .default        { background-image: url(./images/2x/gogo.png);}
    .no_result_01   { background-image: url(./images/2x/no-result-01.png);}
    .no_result_02   { background-image: url(./images/2x/no-result-02.jpg);}
    .no_result_03   { background-image: url(./images/2x/no-result-03.png);}
    .no_result_04   { background-image: url(./images/2x/no-result-04.png);}
    .no_result_05   { background-image: url(./images/2x/no-result-05.png);}
    .no_result_06   { background-image: url(./images/2x/no-result-06.png);}
    .no_result_07   { background-image: url(./images/2x/no-result-07.png);}
    .no_result_08   { background-image: url(./images/2x/no-result-08.jpg);}
    .no_menu        { background-image: url(./images/2x/no-menu.png);}
    .welcome        { background-image: url(./images/2x/welcome.png);}
    .like           { background-image: url(./images/2x/like.png);}
    .gogo           { background-image: url(./images/2x/gogo.png);}
    .teacher        { background-image: url(./images/2x/teacher.png);}
    .congratulation { background-image: url(./images/2x/congratulation.png);}
}

@media(min-width:328px){
    .container {width: 328px;}
}
